import React from "react"
import HomeContainer from "../containers/HomeContainer"
import HeadData from "../data/HeadData"
import FullHeaderImageBg from '../headers/FullHeaderImageBg'
import HeaderNav from '../navigation/HeaderNav'
import HeaderText from "../headers/HeaderText"
import HomeServicesSection from "../sections/HomeServicesSection"
import HomeAboutSection from "../sections/HomeAboutSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"
import HomeOptionsSection from "../sections/HomeOptionsSection"

export default () => (
  <HomeContainer>
    <HeadData isHome />

    <FullHeaderImageBg>
      <HeaderNav />
      <HeaderText
        title='Your Property’s Success starts with IAM'
        subTitle='Providing superior management services to community associations, commercial and residential properties throughout Southern California.' 
        withButton
        buttonText='Request Information'
        linkTo='/request-info'
      />
    </FullHeaderImageBg>

    <HomeOptionsSection />

    <HomeServicesSection />

    <HomeAboutSection />

    <HomeAffiliationSection />

    <CTASection />
  </HomeContainer>
)
