import React from 'react'
import { Link } from 'gatsby'

const HomeOptionsSection = () => (
  <div className='sm:block flex bg-gray-100 shadow-lg'>
    <div className='flex flex-col items-center justify-center sm:w-full w-1/3 p-16 md:p-8 bg-blue-900'>
      <svg className="fill-current text-blue-100 h-12 w-12 mb-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6.47 9.8A5 5 0 0 1 .2 3.22l3.95 3.95 2.82-2.83L3.03.41a5 5 0 0 1 6.4 6.68l10 10-2.83 2.83L6.47 9.8z"/></svg>
  
      <h2 className='text-3xl md:text-xl text-center font-bold text-blue-100 uppercase mb-4'>Schedule Service</h2>

      <p className='text-blue-100 text-center mb-4'>Contact us now to schedule a service call in your area. We work quickly within your time-frame.</p>

      <Link to='/request-service'>
        <button className='bg-white py-4 px-8 md:py-2 md:px-4 text-blue-700 hover:text-blue-900 border hover:border-blue-300 text-lg md:text-sm font-bold shadow-xl'>Schedule a Service Call</button>
      </Link>
    </div>

    <div className='flex flex-col items-center justify-center sm:w-full w-1/3 p-16 md:p-8 bg-blue-600'>
      <svg className="fill-current text-blue-100 h-12 w-12 mb-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm1-5h1a3 3 0 0 0 0-6H7.99a1 1 0 0 1 0-2H14V5h-3V3H9v2H8a3 3 0 1 0 0 6h4a1 1 0 1 1 0 2H6v2h3v2h2v-2z"/></svg>

      <h2 className='text-3xl md:text-xl text-center font-bold text-blue-100 uppercase mb-4'>Make a Payment</h2>

      <p className='text-blue-100 text-center mb-4'>Easily make a payment online. All electronic payments will post immediately. Pay via major credit card or e-check.</p>

      <a href='https://www.paylease.com/index_out.php?pm_id=4710652' target='_blank' rel='noopener noreferrer'>
        <button className='bg-white py-4 px-8 md:py-2 md:px-4 text-blue-700 hover:text-blue-900 border hover:border-blue-300 text-lg md:text-sm font-bold shadow-xl'>Make an Online Payment</button>
      </a>
    </div>

    <div className='flex flex-col items-center justify-center sm:w-full w-1/3 p-16 md:p-8 bg-blue-400'>
      <svg className="fill-current text-white h-12 w-12 mb-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z"/></svg>

      <h2 className='text-3xl md:text-xl text-center font-bold text-white uppercase mb-4'>Make an Inquiry</h2>

      <p className='text-white text-center mb-4'>We offer customer service around the clock. Contact us at any time and get help when you need it.</p>

      <Link to='/request-info'>
        <button className='bg-white py-4 px-8 md:py-2 md:px-4 text-blue-700 hover:text-blue-900 border hover:border-blue-300 text-lg md:text-sm font-bold shadow-xl'>Ask a Question</button>
      </Link>
    </div>
  </div>
)

export default HomeOptionsSection