import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-bottom: 22px;
`

const HomeServicesSection = () => (
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid
        const imageDataTwo = data.imageTwo.childImageSharp.fluid
        const imageDataThree = data.imageThree.childImageSharp.fluid

        return (
          <div className='sm:w-11/12 sm:my-12 md:w-11/12 md:my-16 lg:w-10/12 lg:my-24 mx-auto'>
            <div className='flex items-start sm:flex-col sm:flex-col-reverse md:flex-col md:flex-col-reverse lg:flex-row-reverse mb-16'>
              <div className='w-full lg:w-3/6 flex flex-col justify-center'>
                <StyledUnderline className='sm:mt-6 md:mt-6'></StyledUnderline>

                <Link to='/community-associations'>
                  <h2 className='font-display text-blue-900 text-4xl font-bold md:mb-6 lg:mb-6 leading-tight'>Community Associations</h2>
                </Link>

                <p className='text-gray-700 text-lg leading-relaxed lg:pr-8'>Interpacific Asset Management currently manages a wide variety of community associations, from very small condominium complexes with only a few units, to large highrises and planned communities. We pride ourselves in providing timely responses to Board members and homeowners, while providing swift and effective resolution to maintenance issues for each and every association and its members. Our utmost goal is to enhance your community’s property values and the quality of life of its residents.</p>
              </div>

              <div className='w-full lg:w-4/6 lg:ml-0 lg:mr-8'>
                <Img className='z-0 shadow-xl' fluid={imageDataOne} alt='Image of a residential house' />
              </div>
            </div>

            <div className='flex items-start sm:flex-col sm:flex-col-reverse md:flex-col md:flex-col-reverse  mb-16'>
              <div className='w-full lg:w-3/6 flex flex-col justify-center'>
                <StyledUnderline className='sm:mt-6 md:mt-6'></StyledUnderline>

                <Link to='/services-commercial'>
                  <h2 className='font-display text-blue-900 text-4xl font-bold md:mb-6 lg:mb-6 leading-tight'>Commercial Services</h2>
                </Link>

                <p className='text-gray-700 text-lg leading-relaxed lg:pr-8'>Interpacific Asset Management offers full-service management and leasing services and currently manages a wide variety of commercial properties, from retail centers, to industrial parks, to office buildings. We strongly believe in providing only the most efficient and professional service possible to each and every owner and their tenants.</p>
              </div>

              <div className='w-full lg:w-4/6 lg:ml-8 lg:mr-0'>
                <Img className='z-0 shadow-xl' fluid={imageDataTwo} alt='Image of a commercial building' />
              </div>
            </div>

            <div className='flex items-start sm:flex-col sm:flex-col-reverse md:flex-col md:flex-col-reverse lg:flex-row-reverse mb-16'>
              <div className='w-full lg:w-3/6 flex flex-col justify-center'>
                <StyledUnderline className='sm:mt-6 md:mt-6'></StyledUnderline>

                <Link to='/services-residential'>
                  <h2 className='font-display text-blue-900 text-4xl font-bold md:mb-6 lg:mb-6 leading-tight'>Residential Services</h2>
                </Link>

                <p className='text-gray-700 text-lg leading-relaxed lg:pr-8'>Our full service management program includes interfacing with tenants and owners, collection of all rents and expense charges, coordinating and following up with service and maintenance requests, and, if requested, providing referrals for contractors and vendors who are licensed, bonded, insured, and priced competitively. We will also arrange for eviction of any tenants who have failed to meet their rental obligation and act as liaison for legal counsel in this process.</p>
              </div>

              <div className='w-full lg:w-4/6 lg:ml-0 lg:mr-8'>
                <Img className='z-0 shadow-xl' fluid={imageDataThree} alt='Image of a residential house' />
              </div>
            </div>
          </div>
        )
      }
    }
  />    
)

export default HomeServicesSection

const query = graphql`
  query HomeServicesSectionQuery {
    imageOne: file(relativePath: { eq: "services/home-service-residential-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "services/home-service-commercial-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "services/home-service-residential-bg-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`